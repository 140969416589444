import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useEventSchedulerAnalytics } from '../../analytics/eventScheduler';
import { type GamePack } from '../../types/game';
import { GamePackSelect } from '../Game/GameCenter/GamePackSelect';
import { GamePackPicker } from '../Game/GamePackPicker';

export function EventGamePackPicker(props: {
  onCancel: () => void;
  onSelect: (gamePack: GamePack) => void;
  featured: boolean;
  modalTitle?: string;
  modalTips?: string;
}): JSX.Element {
  const analytics = useEventSchedulerAnalytics();
  useEffectOnce(() => {
    analytics.trackGamePackSelectorViewed();
  });

  const [selected, setSelected] = useState<GamePack | undefined>();
  const [showFull, setShowFull] = useState(false);

  const handleCancel = () => {
    analytics.trackGamePackSelectorDismissed();
    props.onCancel();
  };

  const handleSelect = () => {
    if (selected) {
      props.onSelect(selected);
    }
  };

  if (props.featured || showFull) {
    return <GamePackPicker onCancel={handleCancel} onSelect={props.onSelect} />;
  }

  return (
    <div className='w-full h-full flex flex-col items-center text-white'>
      <div className='text-bold text-xl text-center'>
        {props.modalTitle ?? 'Choose from our Featured Games'}
      </div>
      <div className='text-sms mt-7 mb-10 text-center'>
        {props.modalTips ??
          'These are some recommendations. Don’t worry, you can always change this later and play as many games as you’d like.'}
      </div>
      <div className='w-full'>
        <div>
          <div className='font-bold mb-1'>Game Pack</div>
          <GamePackSelect
            value={selected}
            onChange={(gamePack) => {
              if (gamePack == null) return;
              setSelected(gamePack);
            }}
          />
          {!showFull && (
            <div className='mt-2 w-full flex items-center justify-center'>
              <button
                type='button'
                className='btn text-primary text-xs hover:underline'
                onClick={() => setShowFull(true)}
              >
                Open Full Library
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='mt-10 flex items-center justify-center gap-6'>
        <button
          type='button'
          className='btn btn-secondary w-40 h-10'
          onClick={handleCancel}
        >
          Cancel
        </button>

        <button
          type='button'
          className='btn-primary w-40 h-10'
          onClick={handleSelect}
          disabled={selected === undefined}
        >
          Select
        </button>
      </div>
    </div>
  );
}
